import { Component, Prop, Vue } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Carousel, Slide } from "vue-carousel-variable-width";
const DfLinkButtonComponent = () => Utils.externalComponent2("df-link-button");

export enum DfSectionLinksComponentMode {
  Grid,
  Flex,
}

@Component({
  components: {
    DfLinkButton: DfLinkButtonComponent,
    Carousel,
    Slide,
  },
})
export default class DfSectionLinksComponent extends Vue {
  @Prop({ type: String, default: "flex" })
  mode!: string;

  private readonly POSITION_HEADER_LINK: string = "HEADER_LINK";

  get buttons(): Array<DfContent> {
    const storeButtons: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "BTN");
    const brandButtons: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "BTN");

    return this.filterButtonByPosition([...storeButtons, ...brandButtons]).sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));
  }

  filterButtonByPosition(buttons: Array<DfContent>): Array<DfContent> {
    return buttons.filter((linkButton: DfContent) => {
      const valueString: string = Utils.getPropertyValue(linkButton, Utils.PROPERTY_POSITION, "STRING");
      const valueList: any = Utils.getPropertyValue(linkButton, Utils.PROPERTY_POSITION, "LIST", null);
      return (valueList == null && (valueString == null || valueString === this.POSITION_HEADER_LINK)) || valueList.key === "ALL" || valueList.key === this.POSITION_HEADER_LINK;
    });
  }

  buttonVisibility(button: DfContent): string {
    return Utils.getPropertyValue(button, Utils.PROPERTY_BUTTON_VISIBILITY, "STRING", "");
  }

  buttonVisibilityClass(button: DfContent): string {
    const cordova: any = (<any>window).cordova || null;
    const buttonVisibility = this.buttonVisibility(button);
    let buttonClass: string = null;

    if (cordova && buttonVisibility === "MOBILE") {
      buttonClass = "";
    } else if (cordova && buttonVisibility === "DESKTOP") {
      buttonClass = "desktop-in-cordova";
    } else {
      buttonClass = buttonVisibility.toLowerCase();
    }

    return buttonClass;
  }
}
